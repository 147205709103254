<template>
    <div>
        <v-app id="inspire">
            <v-layout wrap justify-center class="mainbgx">
                <v-flex xs12 align-self-center>
                    <v-snackbar v-model="showSnackBar" color="black" right :timeout="timeout">
                        <v-layout wrap justify-center>
                            <v-flex text-left class="align-self-center">
                                <span style="color: white">
                                    {{ msg }}
                                </span>
                            </v-flex>
                            <v-flex text-right>
                                <v-btn small :ripple="false" text @click="showSnackBar = false">
                                    <v-icon style="color: white">mdi-close</v-icon>
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-snackbar>
                    <v-layout wrap justify-end>
                        <v-flex xs7>
                            <v-layout wrap justify-center>
                                <v-flex xs7>
                                    <v-card>
                                        <v-layout wrap justify-center>
                                            <v-flex xs3 pt-5>
                                                <v-img height="60px" contain src="../../assets/Images/blacklogo.png"></v-img>
                                            </v-flex>
                                            <v-flex xs10 text-center pt-2 class="mainfont">
                                                <span style="font-size: 22px;">Register</span>
                                            </v-flex>
                                            <v-flex xs10 pt-6>
                                                <v-text-field color="#717171" label="Name" outlined dense
                                                    style="font-family: mainfont" v-model="officeName" hide-details>
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex xs10 pt-6>
                                                <v-text-field color="#717171" label="User Name" outlined dense
                                                    style="font-family: mainfont" v-model="username" hide-details>
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex xs10 pt-6>
                                                <v-text-field :rules="emailIdRules" color="#717171" label="E-mail" outlined
                                                    dense style="font-family: mainfont" v-model="emailId" hide-details>
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex xs10 pt-6>
                                                <v-text-field class="inputPrice" color="black" outlined background-color="white"
                                                    dense label="Phone Number" type="number" :rules="guestPhoneRules"
                                                    v-model="mobNumber" hide-spin-buttons hide-details>
                                                </v-text-field>
                                            </v-flex>
                                            <v-flex pt-6 xs10>
                                                <v-text-field color="#717171" label="Password" outlined dense
                                                    style="font-family: mainfont" v-model="password"
                                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="showPassword ? 'text' : 'password'"
                                                    @click:append="showPassword = !showPassword" 
                                                    :rules="passwordRules"
                                                    hide-details>
                                                </v-text-field>
                                                <div class="password-requirements" v-if="password">
                                                    <v-alert v-if="!isPasswordValid" type="info" dense>
                                                        Password must contain:
                                                        <ul>
                                                            <li>At least 8 characters</li>
                                                            <li>At least one uppercase letter</li>
                                                            <li>At least one number</li>
                                                            <li>At least one special character (@$!%*?&)</li>
                                                        </ul>
                                                    </v-alert>
                                                </div>
                                            </v-flex>
                                            <v-flex pt-6 xs10>
                                                <v-text-field color="#717171" label="Confirm Password" outlined dense
                                                    style="font-family: mainfont" v-model="confirmpassword"
                                                    :append-icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :type="showPassword2 ? 'text' : 'password'"
                                                    @click:append="showPassword2 = !showPassword2" 
                                                    hide-details>
                                                </v-text-field>
                                                <v-alert v-if="!passwordsMatch && confirmpassword" type="error" dense>
                                                    Passwords do not match
                                                </v-alert>
                                            </v-flex>

                                            <v-flex pt-3 xs10>
                                                <v-btn class="no-uppercase mainfont" dark block color="#314755"
                                                    @click="validateInput" :disabled="!isFormValid">
                                                    <span>
                                                        Submit
                                                        &nbsp;
                                                    </span>
                                                    <v-icon color="white">
                                                        mdi-arrow-right
                                                    </v-icon>
                                                </v-btn>
                                            </v-flex>
                                            <v-flex @click="$router.push('/')" style="cursor: pointer;" pb-8
                                                class="mainfont" text-center pt-3 xs10>
                                                <span>Already a user? click here to Login</span>
                                            </v-flex>
                                        </v-layout>
                                    </v-card>
                                </v-flex>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-app>
    </div>
</template>

<script>
import axios from "axios";
import store from "./../../store";

export default {
    data() {
        return {
            ServerError: false,
            showSnackBar: false,
            timeout: 5000,
            officeName: null,
            msg: null,
            emailId: null,
            mobNumber: null,
            otp: null,
            phone: null,
            showPassword: false,
            showPassword2: false,
            passwordsMatch: true,
            username: null,
            password: null,
            confirmpassword: null,
        };
    },

    mounted() {
        this.phone = this.$route.query.phone;
    },

    watch: {
        confirmpassword(newConfirmPassword) {
            this.passwordsMatch = newConfirmPassword === this.password;
        },
        password(newPassword) {
            if (this.confirmpassword) {
                this.passwordsMatch = this.confirmpassword === newPassword;
            }
        }
    },

    computed: {
    guestPhoneRules() {
        return [
            v => !!v || "Phone number is required",
            v => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
        ];
    },
    emailIdRules() {
        return [
            v => !!v || "E-mail is required",
            v => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ];
    },
    passwordRules() {
        return [
            v => !!v || "Password is required",
            v => /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(v) || 
                "Password must contain at least 8 characters, one uppercase letter, one number, and one special character"
        ];
    },
    isPasswordValid() {
        const passwordPattern = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return this.password ? passwordPattern.test(this.password) : false;
    },
    isFormValid() {
        return this.officeName && 
               this.username && 
               this.emailId && 
               this.mobNumber && 
               this.password && 
               this.confirmpassword && 
               this.isPasswordValid && 
               this.passwordsMatch;
    }
},

    methods: {
        validateInput() {
            if (!this.isFormValid) {
                this.msg = "Please fill in all required fields and ensure password meets requirements";
                this.showSnackBar = true;
                return;
            }
            this.register();
        },

        register() {
            axios({
                method: "POST",
                url: "/add/user/",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    username: this.username,
                    officeName: this.officeName,
                    password: this.password,
                    mobNumber: this.mobNumber,
                    emailId: this.emailId,
                },
            })
                .then((response) => {
                    if (response.data.status == true) {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                        const userData = response.data.data;
                        const userId = userData._id;
                        setTimeout(() => {
                            this.$router.push({ path: "/verify", query: { userId } });
                        }, 3000);
                        // this.$router.push({ path: "/" });
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.error(err);
                });
        },
        login() {
            axios({
                method: "post",
                url: "/login/",
                data: {
                    username: this.username,
                    password: this.password,
                },
            })
                .then((response) => {
                    if (response.data.status) {
                        store.commit("loginUser", response.data.token);
                        var utype = response.data.utype;
                        var path = response.data.path;
                        localStorage.setItem("utype", utype);
                        localStorage.setItem("path", path);
                        if (utype === 777) {
                            console.log("hghbg")
                            this.$router.push({ path: "/DashboardPage" });
                        } else {
                            this.$router.push({ path: "/divisiondashboard" });
                        }
                    } else {
                        this.msg = response.data.msg;
                        this.showSnackBar = true;
                    }
                })
                .catch((err) => {
                    store.commit("appLoading", false);
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>

<style>
.mainbgx {
    background-image: linear-gradient(269.6deg, #1cb5e0 -31.66%, #000046);
}

/* Apply a gradient background color to an element with class 'bgcustom' */
.bgcustom {
    background: linear-gradient(180deg, #314755 0%, #26a0da 100%);
    color: white;
    /* Set the text color */
}
.no-spinners {
    /* Remove the up and down arrows */
    appearance: textfield;
    -moz-appearance: textfield;
    -webkit-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>